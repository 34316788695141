const salesReport = () => import(/* webpackPrefetch: true */ '@/views/private/salesReport/Index.vue');
const SalesSummary = () => import(/* webpackPrefetch: true */ '@/components/salesReport/salesSummary/Index.vue');
const SalesVoid = () => import(/* webpackPrefetch: true */ '@/components/salesReport/salesVoid/Index.vue');
const salesRefund = () => import(/* webpackPrefetch: true */ '@/components/salesReport/salesRefund/Index.vue');
const SalesCustomerCategoryList = () => import(/* webpackPrefetch: true */ '@/components/salesReport/salesCustomerCategoryList/Index.vue');
const SalesCategoryList = () => import(/* webpackPrefetch: true */ '@/components/salesReport/salesCategoryList/Index.vue');
const SalesProfitProduct = () => import(/* webpackPrefetch: true */ '@/components/salesReport/salesProfitProduct/Index.vue');
const salesDaily = () => import(/* webpackPrefetch: true */ '@/components/salesReport/salesDaily/Index.vue');
const salesYearly = () => import(/* webpackPrefetch: true */ '@/components/salesReport/salesYearly/Index.vue');
const salesMonthly = () => import(/* webpackPrefetch: true */ '@/components/salesReport/salesMonthly/Index.vue');
const SalesEmployees = () => import(/* webpackPrefetch: true */ '@/components/salesReport/salesEmployees/Index.vue');
const salesPaymentMethod = () => import(/* webpackPrefetch: true */ '@/components/salesReport/salesPaymentMethod/Index.vue');
const salesSalesType = () => import(/* webpackPrefetch: true */ '@/components/salesReport/salesSalesType/Index.vue');
const SalesProductList = () => import(/* webpackPrefetch: true */ '@/components/salesReport/salesProductList/Index.vue');

const routes = [
  {
		path: "/sales",
		name: "sales report",
		component: salesReport,
		meta: {
			title: "Sales Report"
		},
		children: [
			{
				path: "/sales/summary",
				name: "sales summary",
				component: SalesSummary,
				slug: "summary",
				meta: {
					reuse: false,
					title: "Sales Summary"
				}
			},
			{
				path: "/sales/refund",
				name: "sales refund",
				component: salesRefund,
				slug: "refund",
				meta: {
					reuse: false,
					title: "Sales refund"
				}
			},
			{
				path: "/sales/void",
				name: "sales Void",
				component: SalesVoid,
				slug: "void",
				meta: {
					reuse: false,
					title: "Sales Void"
				}
			},
      {
				path: "/sales/perProduct",
				name: "sales product",
				component: SalesProductList,
				slug: "productSales",
				meta: {
					title: "Sales Product"
				}
			},
      {
				path: "/sales/perEmployees",
				name: "sales employees",
				component: SalesEmployees,
				slug: "employee",
				meta: {
					title: "Sales Employees"
				}
			},
			{
				path: "/sales/typePayment",
				name: "sales payment",
				component: salesPaymentMethod,
				slug: "paymentMethod",
				meta: {
					title: "Sales Payment"
				}
			},
			{
				path: "/sales/perType",
				name: "sales type payment",
				component: salesSalesType,
				slug: "salesSalesType",
				meta: {
					title: "Sales Type Payment"
				}
			},
			{
				path: "/sales/perCustomerCategory",
				name: "sales Customer Category",
				component: SalesCustomerCategoryList,
				slug: "customercategorySales",
				meta: {
					title: "Sales Customer Category"
				}
			},
			{
				path: "/sales/perCategory",
				name: "sales kategori",
				component: SalesCategoryList,
				slug: "categorySales",
				meta: {
					title: "Sales Category"
				}
			},
			{
				path: "/sales/profitProduct",
				name: "sales profit",
				component: SalesProfitProduct,
				slug: "productProfit",
				meta : {
					title : "Sales Profit"
				}
			},
			{
				path: "/sales/perDaily",
				name: "sales hour",
				component: salesDaily,
				slug: "daily",
				meta : {
					title : "Sales Hour"
				}
			},
			{
				path: "/sales/perMonthly",
				name: "sales Month",
				component: salesMonthly,
				slug: "monthly",
				meta : {
					title : "Sales Month"
				}
			},
			{
				path: "/sales/perYearly",
				name: "sales Year",
				component: salesYearly,
				slug: "yearly",
				meta : {
					title : "Sales Year"
				}
			},
    ]
  }
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
  return { ...route, meta }
})