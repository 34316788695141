const salesReport = () => import(/* webpackPrefetch: true */ '@/views/private/reportRecapSales/Index.vue');
const SalesSummary = () => import(/* webpackPrefetch: true */ '@/components/salesReportRecap/salesSummary/Index.vue');
const SalesVoid = () => import(/* webpackPrefetch: true */ '@/components/salesReportRecap/salesVoid/Index.vue');
const salesRefund = () => import(/* webpackPrefetch: true */ '@/components/salesReportRecap/salesRefund/Index.vue');
const SalesCustomerCategoryList = () => import(/* webpackPrefetch: true */ '@/components/salesReportRecap/salesCustomerCategoryList/Index.vue');
const SalesCategoryList = () => import(/* webpackPrefetch: true */ '@/components/salesReportRecap/salesCategoryList/Index.vue');
const SalesProfitProduct = () => import(/* webpackPrefetch: true */ '@/components/salesReportRecap/salesProfitProduct/Index.vue');
const salesDaily = () => import(/* webpackPrefetch: true */ '@/components/salesReportRecap/salesDaily/Index.vue');
const salesYearly = () => import(/* webpackPrefetch: true */ '@/components/salesReportRecap/salesYearly/Index.vue');
const salesMonthly = () => import(/* webpackPrefetch: true */ '@/components/salesReportRecap/salesMonthly/Index.vue');
const SalesEmployees = () => import(/* webpackPrefetch: true */ '@/components/salesReportRecap/salesEmployees/Index.vue');
const salesPaymentMethod = () => import(/* webpackPrefetch: true */ '@/components/salesReportRecap/salesPaymentMethod/Index.vue');
const salesSalesType = () => import(/* webpackPrefetch: true */ '@/components/salesReportRecap/salesSalesType/Index.vue');
const SalesProductList = () => import(/* webpackPrefetch: true */ '@/components/salesReportRecap/salesProductList/Index.vue');

const routes = [
  {
		path: "/report-recap-sales/:id",
		name: "sales report",
		component: salesReport,
		meta: {
			title: "Sales Report"
		},
		children: [
			{
				path: "/report-recap-sales/summary/:id",
				name: "sales summary",
				component: SalesSummary,
				slug: "summary",
				meta: {
					title: "Sales Summary",
					slug: 'sales-report-recap'
				}
			},
			{
				path: "/report-recap-sales/refund/:id",
				name: "sales refund",
				component: salesRefund,
				slug: "refund",
				meta: {
					title: "Sales refund",
					slug: 'sales-report-recap'
				}
			},
			{
				path: "/report-recap-sales/void/:id",
				name: "sales Void",
				component: SalesVoid,
				slug: "void",
				meta: {
					title: "Sales Void",
					slug: 'sales-report-recap'
				}
			},
      {
				path: "/report-recap-sales/perProduct/:id",
				name: "sales product",
				component: SalesProductList,
				slug: "productSales",
				meta: {
					title: "Sales Product",
					slug: 'sales-report-recap'
				}
			},
      {
				path: "/report-recap-sales/perEmployees/:id",
				name: "sales employees",
				component: SalesEmployees,
				slug: "employee",
				meta: {
					title: "Sales Employees",
					slug: 'sales-report-recap'
				}
			},
			{
				path: "/report-recap-sales/typePayment/:id",
				name: "sales payment",
				component: salesPaymentMethod,
				slug: "paymentMethod",
				meta: {
					title: "Sales Payment",
					slug: 'sales-report-recap'
				}
			},
			{
				path: "/report-recap-sales/perType/:id",
				name: "sales type payment",
				component: salesSalesType,
				slug: "salesSalesType",
				meta: {
					title: "Sales Type Payment",
					slug: 'sales-report-recap'
				}
			},
			{
				path: "/report-recap-sales/perCustomerCategory/:id",
				name: "sales Customer Category",
				component: SalesCustomerCategoryList,
				slug: "customercategorySales",
				meta: {
					title: "Sales Customer Category",
					slug: 'sales-report-recap'
				}
			},
			{
				path: "/report-recap-sales/perCategory/:id",
				name: "sales kategori",
				component: SalesCategoryList,
				slug: "categorySales",
				meta: {
					title: "Sales Category",
					slug: 'sales-report-recap'
				}
			},
			{
				path: "/report-recap-sales/profitProduct/:id",
				name: "sales profit",
				component: SalesProfitProduct,
				slug: "productProfit",
				meta : {
					title : "Sales Profit",
					slug: 'sales-report-recap'
				}
			},
			{
				path: "/report-recap-sales/perDaily/:id",
				name: "sales hour",
				component: salesDaily,
				slug: "daily",
				meta : {
					title : "Sales Hour",
					slug: 'sales-report-recap'
				}
			},
			{
				path: "/report-recap-sales/perMonthly/:id",
				name: "sales Month",
				component: salesMonthly,
				slug: "monthly",
				meta : {
					title : "Sales Month",
					slug: 'sales-report-recap'
				}
			},
			{
				path: "/report-recap-sales/perYearly/:id",
				name: "sales Year",
				component: salesYearly,
				slug: "yearly",
				meta : {
					title : "Sales Year",
					slug: 'sales-report-recap'
				}
			},
    ]
  }
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
  return { ...route, meta }
})