const Profile = () => import(/* webpackPrefetch: true */ '@/views/private/Profile.vue');

const routes = [
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      title: "Profile"
    }
  },
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
	return { ...route, meta }
})