<template>
  <div v-if="token">
    <unAuthorized />
    <errorNetwork />

    <!-- ==== DIALOG KONFIRMASI GANTI TOKO ==== -->
    <v-dialog v-model="dialog.confirmation" persistent max-width="450">
      <v-card class="border-radius box-shadow">
        <v-card-text style="height: 400px">
          <v-img
            src="@/assets/images/confirmation.png"
            class="ml-auto mr-auto"
            max-width="500"
          >
          </v-img>
          <p class="headline font-weight-bold text-center">
            SELAMAT &#127881;
            <!-- <v-icon>mdi-party-popper</v-icon> -->
          </p>
          <p class="mt-5 text-center">
            Anda berhasil pindah ke Outlet
            <span class="body-1 font-weight-bold">{{ store_change }}</span>
          </p>
        </v-card-text>
        <v-card-actions class="pb-5 mt-3 mx-3">
          <v-spacer></v-spacer>
          <v-btn
            small
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="
              dialog.confirmation = false;
              loading();
            "
          >
            <v-icon color="#d31145" small class="mr-1">mdi-check-circle</v-icon>
            Ambil data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG KONFIRMASI LOGOUT ==== -->
    <v-dialog v-model="dialog.logout" persistent max-width="450">
      <v-card class="border-radius box-shadow">
        <v-card-text style="height: 400px">
          <v-img
            src="@/assets/images/logout.png"
            class="ml-auto mr-auto"
            max-width="500"
          >
          </v-img>
          <p class="headline font-weight-bold text-center">
            Yaaahh &#128553;
            <!-- <v-icon class="ml-2">mdi-emoticon-frown-outline</v-icon> -->
          </p>
          <p class="mt-5 body-2 font-weight-regular text-center">
            Yakin kamu ingin keluar ?
          </p>
        </v-card-text>
        <v-card-actions class="pb-5 mx-3">
          <v-spacer></v-spacer>
          <v-btn
            small
            depressed
            width="100"
            color="#d31145"
            :loading="process.run"
            :disabled="process.run"
            class="white--text text-capitalize"
            @click="logout"
          >
            <v-icon color="#fff" small class="mr-1">mdi-check-circle</v-icon>
            Ya
          </v-btn>
          <v-btn
            small
            outlined
            width="100"
            color="red"
            :disabled="process.run"
            class="text-capitalize"
            @click="dialog.logout = false"
          >
            <v-icon color="red" small class="mr-1">mdi-close-circle</v-icon>
            Tidak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG KETIKA MEILIH TOKO YANG SAMA ==== -->
    <v-dialog v-model="dialog.store_now" persistent max-width="425">
      <v-card class="pa-3">
        <v-card-title class="title">Peringatan !</v-card-title>
        <v-card-text v-if="storeReponse == 'same store'">
          Anda tidak dapat berpindah ke Outlet yang sama .
        </v-card-text>
        <v-card-text v-if="storeReponse == 'gagal'">
          Gagal untuk pindah ke Outlet yang lain .
        </v-card-text>
        <v-card-actions class="pb-1">
          <v-spacer></v-spacer>
          <v-btn
            small
            width="100"
            color="#d31145"
            elevation="0"
            :disabled="process.form"
            :loading="process.form"
            class="white--text text-capitalize"
            @click="dialog.store_now = false"
          >
            Oke
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG PILIH TOKO ==== -->
    <v-dialog v-model="dialog.selectStore" scrollable persistent max-width="400">
      <v-card rounded="lg">
        <v-card-text>
          <v-btn
            v-show="selected_store_id != 0"
            class="mt-2 mr-5"
            @click="dialog.selectStore = false"
            icon
            absolute
            right
          >
            <v-icon color="white"> mdi-close-circle </v-icon>
          </v-btn>
          <div
            style="background-color: #d31145; height: 7vh"
            class="rounded-b-xl d-flex justify-center align-center"
            width="100%"
          >
            <span class="white--text overline"> Pilih Outlet</span>
          </div>
        </v-card-text>
        <v-card-text style="height: 500px">
          <v-list 
            v-for="(item, index) in data_store"
            :key="index"
            class="pa-0"
            dense>
            <v-list-item
              dense
              :disabled="
                item.store_is_active === 'Tidak aktif' || process.run
              "
              v-ripple
              @click="
                selected_store_id.toString() === item.store_id
                  ? storeNow()
                  : update(item)
              ">
              <v-list-item-avatar tile size="30">
                <v-img :src="item.image_url ? item.image_url : 'https://sodapos.com/media/2022/11/image/shop-icon-1669016747.png'">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0 justify-center"
                      align="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#d31145"
                      >
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <b>{{ item.store_name }}</b>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.owner_name }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon v-if="selected_store_id.toString() === item.store_id" color="#d31145">mdi-radiobox-marked</v-icon>
                <v-icon v-else>mdi-radiobox-blank</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
          <!-- <v-row align="center" class="mt-3">
            <v-col
              cols="12"
              md="4"
              class="pa-2"
              v-for="(item, index) in data_store"
              :key="index">
              <v-card
                :disabled="
                  item.store_is_active === 'Tidak aktif' || process.run
                "
                color="#ecf0f1"
                class="elevation-0 rounded-lg c-p mb-5"
                v-ripple
                @click="
                  selected_store_id.toString() === item.store_id
                    ? storeNow()
                    : update(item)
                "
              >
                <v-row class="fill-height justify-center">
                  <v-col cols="12" justify="center" align="center">
                    <v-avatar size="80" tile>
                      <v-img :src="item.image_url ? item.image_url : 'https://sodapos.com/media/2022/11/image/shop-icon-1669016747.png'">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0 justify-center"
                            align="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#d31145"
                            >
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-header-1 text-center">
                      <b>{{ item.store_name }}</b>
                    </div>
                    <p
                      class="caption grey--text text-center address-text"
                      v-html="item.owner_name"
                    ></p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== NAVBAR ATAS ==== -->
    <v-app-bar
      style="z-index: 3"
      fixed
      app
      :color="$vuetify.theme.dark ? 'dark' : 'white'"
      elevation="0"
    >
      <!-- v-show="$route.name !== 'dashboard'" -->
      <v-app-bar-nav-icon
        :color="$vuetify.theme.dark ? 'white' : 'blue-grey darken-1'"
        v-if="
          $vuetify.breakpoint.name === 'xs' ||
          $vuetify.breakpoint.name === 'sm' ||
          $vuetify.breakpoint.name === 'md'
        "
        @click.stop="drawer = !drawer"
      />
      <v-toolbar-title
        v-show="$vuetify.breakpoint.name === 'lg'"
        @click="$router.push('/dashboard')"
        :class="$vuetify.breakpoint.name === 'xs' ? 'c-p' : 'c-p ml-7 text-uppercase font-weight-bold text-second text-h5'"
      >
        <v-img :width="$vuetify.breakpoint.name === 'xs' ? 70 : 200" height="auto" src="@/assets//logo/logo-depan.png"></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="text-left mr-3">
        <!-- v-show="metaSlug != 'sales-report-recap'" -->
        <v-sheet :width="$vuetify.breakpoint.name === 'xs' ? 200 : 300">
          <v-list-item
            dense
            @click="storeDisabled"
            class="py-0 pt-0 pb-0 pl-1 my-0"
            style="
              background-color: #d31145;
              border-color: #d31145;
              border-radius: 50px;
              font-size: 19px;
            "
          >
            <v-list-item-avatar
              class="my-0"
              :style="
              $vuetify.breakpoint.name === 'xs' ? 
               'height: 32px; min-width: 32px; width: 32px; border: 3px solid rgb(255, 255, 255); background-color: white;'
              : 
                'height: 32px; min-width: 32px; width: 32px; border: 3px solid rgb(255, 255, 255); background-color: white;'
              "
            >
              <v-img :src="selected_store.image_url ? selected_store.image_url : 'https://sodapos.com/media/2022/11/image/shop-icon-1669016747.png'">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0 justify-center"
                    align="center">
                    <v-progress-circular indeterminate color="#d31145">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-list-item-avatar>
            <v-list-item-content class="py-0 mt-0">
              <v-list-item-title class="white--text pt-0">
                {{ selected_store.store_name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon v-if="data_store.length > 1" :class="$vuetify.breakpoint.name === 'xs' ? 'ml-0' : ''">
              <v-icon color="white" right>mdi-menu-down</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-sheet>
      </div>
    </v-app-bar>

    <!-- ==== NAVIGATION DRAWER BAGIAN SAMPING KIRI ==== -->
    <v-navigation-drawer
      dark
      color="#d31145"
      width="90"
      v-model="drawer"
      fixed
      app
    >
      <div class="text-center pa-3 px-0 mb-0">
        <router-link to="/profile">
          <v-avatar class="box-shadow" size="55">
            <v-img v-show="user.image_url" :src="user.image_url">
              <template v-slot:placeholder>
                <v-row class="fill-height justify-center ma-0" align="center">
                  <v-progress-circular indeterminate color="#d31145">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </router-link>
      </div>
      <div class="text-center pa-3 px-0 mb-0">
        <v-list>
          <v-list-item class="px-0">
            <v-card
              width="75"
              flat
              height="75"
              color="transparent"
              class="cursor-pointer ma-auto mx-1"
              to="/dashboard"
            >
              <v-card-title class="justify-center pb-1 my-0">
                <v-icon
                  color="#ffffff"
                  :style="'/dashboard' === $route.path ? '' : 'opacity: 100%'"
                  >mdi-apps</v-icon
                >
              </v-card-title>
              <v-card-text
                class="text-center pa-0 white--text"
                style="font-size: 10px"
                :class="'/dashboard' === $route.path ? 'font-weight-bold' : ''"
                :style="
                  '/dashboard' === $route.path
                    ? 'line-height: 1.5;'
                    : 'opacity: 100%; line-height: 1.5;'
                "
              >
                Dashboard
              </v-card-text>
            </v-card>
            <v-divider
              v-if="'/dashboard' === $route.path"
              class="divider-menu"
              vertical
              light
            >
            </v-divider>
          </v-list-item>
        </v-list>
      </div>
      <v-list v-for="(item, index) in menu" :key="index">
        <v-list-item class="px-0">
          <v-card
            width="75"
            height="75"
            color="transparent"
            flat
            :disabled="item.disabled"
            class="cursor-pointer ma-auto mx-1"
            :to="item.to"
          >
            <v-card-title class="justify-center pb-1 my-0">
              <v-icon
                color="#ffffff"
                :style="
                  item.to.split('/')[1] === comparePath.split('/')[1]
                    ? ''
                    : 'opacity: 100%'
                "
                >{{ item.icon }}</v-icon
              >
            </v-card-title>
            <v-card-text
              class="text-center pa-0 white--text"
              style="font-size: 11px"
              :class="
                item.to.split('/')[1] === comparePath.split('/')[1]
                  ? 'font-weight-bold'
                  : ''
              "
              :style="
                item.to.split('/')[1] === comparePath.split('/')[1]
                  ? 'line-height: 1.5;'
                  : 'opacity: 100%; line-height: 1.5;'
              "
            >
              {{ item.text }}
            </v-card-text>
          </v-card>
          <v-divider
            v-if="item.to.split('/')[1] === comparePath.split('/')[1]"
            class="divider-menu"
            vertical
            light
          >
          </v-divider>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item class="px-0">
          <v-card
            width="75"
            flat
            height="75"
            color="transparent"
            class="cursor-pointer ma-auto mx-1"
            @click="dialog.logout = true"
          >
            <v-card-title class="justify-center pb-1 my-0">
              <v-icon color="#ffffff" :style="'opacity: 100%'"
                >mdi-logout</v-icon
              >
            </v-card-title>
            <v-card-text
              class="text-center pa-0 white--text"
              style="font-size: 10px; opacity: 100%"
            >
              Keluar
            </v-card-text>
          </v-card>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { get, post, put, destroy } from "@/service/Axios";
import { TokenService } from "@/service/Storage.Service";
import unAuthorized from "../components/401.vue";
import errorNetwork from "../components/500.vue";
import store from "@/store";
export default {
  components: {
    unAuthorized,
    errorNetwork,
  },
  data(vm) {
    return {
      comparePath: "",
      model: null,
      drawer: true,
      darkMode: false,
      miniVariant: false,
      initialDark: vm.$vuetify ? vm.$vuetify.theme.dark : false,
      menu_baru: [],
      menuOld: [
        // {
        //   text: "Dashboard",
        //   icon: "mdi-apps",
        //   to: "/dashboard",
        //   hasParent: 0,
        //   disabled: false,
        // },
        {
          text: "Persediaan",
          icon: "mdi-clipboard-file",
          to: "/inventory",
          hasParent: 0,
          disabled: this.selected_store_id == 0 ? true : false,
        },
        {
          text: "Rekap Data",
          icon: "mdi-archive",
          to: "/recap",
          hasParent: 0,
          disabled: this.selected_store_id == 0 ? true : false,
        },
        {
          text: "Laporan Rekap",
          icon: "mdi-clipboard-text-multiple",
          to: "/report-recap",
          hasParent: 0,
          disabled: this.selected_store_id == 0 ? true : false,
        },
        {
          text: "Laporan Penjualan",
          icon: "mdi-clipboard-text",
          to: "/sales",
          hasParent: 0,
          disabled: this.selected_store_id == 0 ? true : false,
        },
        {
          text: "Outlet",
          icon: "mdi-storefront",
          to: "/store",
          hasParent: 0,
          disabled: this.selected_store_id == 0 ? true : false,
        },
        {
          text: "Invitation",
          icon: "mdi-account-group",
          to: "/invitations",
          hasParent: 0,
          disabled: false,
        },
      ],
      notification: [],
      storeItem: [],
      count: 0,
      storeReponse: "",
      store_change: "",
      store_image: "",
      store_address: "",
      detail: {},
      dialog: {
        logout: false,
        confirmation: false,
        dialogNotifications: false,
        selectStore: false,
        store_now: false,
        upgradeInfo: false,
      },
      process: {
        run: false,
      },
      metaSlug: ""
    };
  },
  watch: {
    $route(to, from) {
      if (from.matched.length > 0) {
        this.metaSlug= to.meta.slug
        this.comparePath = this.$route.path;
        if (to.path == from.matched[0].path) {
          this.$router.push(from.path);
        }

      }
      this.fetchCountNotification();
      this.updateDataOutlet()
    },
  },
  computed: {
    storeName() {
      let name = "";
      // this.user.data_store.forEach((element) => {
      //   if (element.store_id == this.user.selected_store_id) {
      //     name = element.store_name;
      //     this.store_image = element.store_image_url;
      //     this.store_address = element.store_address;
      //   }
      // });
      return name;
    },
    aliasName() {
      let firstAlias = "";
      let secondAlias = "";
      if (this.user && this.user.user_name) {
        let name = this.user.user_name;
        let splitname = name.split(" ");

        if (splitname[0]) {
          firstAlias = splitname[0].substring(0, 1);
        }

        if (splitname[1]) {
          secondAlias = splitname[1].substring(0, 1);
        }
      }
      return firstAlias + secondAlias;
    },
    errorNetwork() {
      return this.$store.state.common.errorNetwork;
    },
    user() {
      return JSON.parse(TokenService.getUser());
    },
    data_store() {
      return JSON.parse(localStorage.getItem('user_store'));
    },
    selected_store() {
      return JSON.parse(localStorage.getItem('selected_store'));
    },
    selected_store_id() {
      return JSON.parse(localStorage.getItem('selected_store_id'));
    },
    token() {
      return this.$store.state.token;
    },
    menu () {
      return [
        // {
        //   text: "Dashboard Recap",
        //   icon: "mdi-chart-tree",
        //   to: "/dashboard-recap",
        //   hasParent: 0,
        //   disabled: this.selected_store_id == 0 ? true : false,
        // },
        {
          text: "Persediaan",
          icon: "mdi-clipboard-file",
          to: "/inventory",
          hasParent: 0,
          disabled: this.selected_store_id == 0 ? true : false,
        },
        {
          text: "Rekap Data",
          icon: "mdi-archive",
          to: "/recap",
          hasParent: 0,
          disabled: this.selected_store_id == 0 ? true : false,
        },
        // {
        //   text: "Lap. Rekap Stok",
        //   icon: "mdi-clipboard-text-multiple",
        //   to: "/report-recap-stock",
        //   hasParent: 0,
        //   disabled: this.selected_store_id == 0 ? true : false,
        // },
        // {
        //   text: "Lap. Rekap Penjualan",
        //   icon: "mdi-clipboard-text-multiple",
        //   to: "/report-recap-sales",
        //   hasParent: 0,
        //   disabled: this.selected_store_id == 0 ? true : false,
        // },
        {
          text: "Laporan Penjualan",
          icon: "mdi-clipboard-text",
          to: "/sales",
          hasParent: 0,
          disabled: this.selected_store_id == 0 ? true : false,
        },
        {
          text: "Outlet",
          icon: "mdi-storefront",
          to: "/store",
          hasParent: 0,
          disabled: this.selected_store_id == 0 ? true : false,
        },
        {
          text: "Invitation",
          icon: "mdi-account-group",
          to: "/invitations",
          hasParent: 0,
          disabled: false,
        },
      ]
    }
  },
  mounted() {
    if (this.token) {
      var date1 = new Date("06/30/2019");
      var date2 = new Date("07/30/2019");

      // To calculate the time difference of two dates
      var Difference_In_Time = date2.getTime() - date1.getTime();

      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      this.fetchCountNotification();
      this.menus_privilage;
      this.data_store.forEach((item) => {
        if (this.selected_store_id == item.store_id) {
          this.$store.state.table_addons = item.store_have_addons_table;
          this.$store.state.order_addons = item.store_have_addons_order;
        }
      });
    }

    if (this.token && this.selected_store_id < 1 && this.data_store.length > 0) {
      this.dialog.selectStore = true;
    }
  },
  methods: {
    async fetchNotification() {},
    async fetchCountNotification() {},
    storeNow() {
      this.dialog.store_now = true;
      this.storeReponse = "same store";
    },
    async updateDataOutlet() {
      await get(`franchise/store`)
      .then((response) => {
        let res = response.data;
        if (res.status === 200) {
          if(res.results.data.length > 0) {
            localStorage.setItem("user_store", JSON.stringify(res.results.data));
          }
        }else {
          this.process.run = false;
          this.error.message = res.message;
        }
      })
    },
    async update(item) {
      localStorage.setItem('selected_store_id', JSON.stringify(parseInt(item.store_id)))
      localStorage.setItem('selected_store', JSON.stringify(item))

      this.store_change = item.store_name;
      this.process.run = false;
      this.dialog.selectStore = false;
      this.dialog.confirmation = true;

      // this.process.run = true;
      // await post(`auth-store/update`, {
      //   data: {
      //     store_id: item.store_id,
      //   },
      // })
      //   .then((res) => {
      //     let data = res.data.data;
      //     if (res.status === 200) {
      //       this.user.selected_store_id = data.store_id;
      //       TokenService.saveUser(JSON.stringify(this.user));
      //       store.commit("setUser", JSON.parse(TokenService.getUser()));
      //       this.store_change = item.store_name;
      //       this.process.run = false;
      //       this.dialog.selectStore = false;
      //       this.dialog.confirmation = true;
      //     } else {
      //       this.process.run = false;
      //       this.storeReponse = "gagal";
      //       this.dialog.selectStore = false;
      //     }
      //   })
      //   .catch((error) => {
      //     if (error.response) {
      //       let errorData = error.response.data;
      //       let errorCode = error.response.status;
      //       let errorHeader = error.response.headers;

      //       if (errorCode === 401) {
      //         this.$store.state.unauthorization = true;
      //         this.process.run = false;
      //         this.process.form = false;
      //       }
      //     } else {
      //       this.$store.state.errorNetwork = true;
      //       this.process.run = false;
      //       this.process.form = false;
      //     }
      //   });
    },
    convert(item) {
      return item.replace(/&lt;/g, "<");
    },
    storeDisabled() {
      if (this.data_store.length > 1) {
        this.dialog.selectStore = true;
      } else {
        this.dialog.selectStore = false;
      }
    },
    toRead(value) {
      this.detail = value;
      put(`internal/notif/update/status/${this.detail.id}`, {
        data: {
          status: "yes",
        },
      }).then((response) => {
        if (response.data.code === 201) {
          this.fetchCountNotification();
          this.dialog = true;
        }
      });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    loading() {
      window.location.reload();
    },
    async logout() {
      this.process.run = true;
      await post(`franchise/logout`, {})
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.clearLocalStorage()
        }else {
          this.clearLocalStorage()
        }
      }).catch((error) => {
        this.clearLocalStorage()
      })
    },
    clearLocalStorage() {
      this.process.run = false;
      TokenService.removeToken();
      localStorage.clear();
      window.location = "/login";
    }
  },
};
</script>

<style>
.c-default {
  color: #f0932b !important;
}
.divider-menu {
  min-height: 50px !important;
  height: 50px;
  width: 26px;
  min-width: 8px;
  margin-top: auto;
  margin-bottom: auto;
  background: #fff;
  border-radius: 10px 0px 0px 10px;
  position: relative;
  right: -1px;
}
.headerthing {
  background-color: #d31145;
  height: 8vh;
}
.c-p {
  cursor: pointer;
}

.bar-app {
  background: linear-gradient(to bottom, #f0932b 0%, #bf6500 100%);
}
.ml-80 {
  margin-left: 20%;
}
.hover-menu:hover,
.actived {
  background: #d311451c !important;
}
</style>
