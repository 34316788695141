const Dashboard = () => import(/* webpackPrefetch: true */ '@/views/private/Dashboard.vue');
const DashboardRecap = () => import(/* webpackPrefetch: true */ '@/views/private/DashboardRecap.vue');

import { TokenService } from "@/service/Storage.Service"; 

const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard"
    }
  },
  {
    path: "/dashboard-recap",
    name: "dashboard recap",
    component: DashboardRecap,
    meta: {
      title: "Dashboard ReKap"
    }
  },
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
  return { ...route, meta }
})