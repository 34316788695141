const AutoLogin = () => import(/* webpackPrefetch: true */ '@/views/public/AutoLogin.vue')

const routes = [
  {
    path: "/al",
    name: "Auto Login",
    component: AutoLogin,
    meta: {
      title: "Auto Login"
    }
  }
]
export default routes.map(route => {
  const meta = {
    all: false, 
    public: true,
    onlyLoggedOut: false,
    title: route.meta.title
  }
  return { ...route, meta }
})