import allRoutes from '@/router/routes/all.js'
import privateAll from '@/router/routes/privateAll.js'
import publicRoutes from '@/router/routes/public.js'
import autologinRoutes from '@/router/routes/autologin.js'
import privateRoutes from '@/router/routes/private.js'
import recapRoutes from '@/router/routes/recap.js'
import invitationRoutes from '@/router/routes/invitation.js'
import inventoryRoutes from '@/router/routes/inventory.js'
import storeRoutes from '@/router/routes/store.js'
import reportRecapStockRoutes from '@/router/routes/reportRecapStock.js'
import reportRecapSalesRoutes from '@/router/routes/reportRecapSales.js'
import transactionRoutes from '@/router/routes/transaction.js'


export default allRoutes.concat(
    privateAll,
    autologinRoutes,
    publicRoutes,
    privateRoutes,
    inventoryRoutes,
    recapRoutes,
    invitationRoutes,
    storeRoutes,
    reportRecapStockRoutes,
    reportRecapSalesRoutes,
    transactionRoutes,
)
