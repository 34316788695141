const Recap = () => import(/* webpackPrefetch: true */ '@/views/private/recap/Index.vue')
const Penjualan = () => import(/* webpackPrefetch: true */ '@/components/recap/penjualan/Index.vue')
const Stock = () => import(/* webpackPrefetch: true */ '@/components/recap/stock/Index.vue')
import { TokenService } from "@/service/Storage.Service"; 

const routes = [
  {
    path: "/recap",
    name: "recap",
    component: Recap,
    meta: {
      title: "Inventaris"
    },
    children: [
      {
        path: '/recap/penjualan',
        name: 'recap penjualan',
        component: Penjualan,
        slug: "penjualan",
        meta: {
          title: "Data Rekap Penjualan"
        }
      },
      {
        path: '/recap/stock',
        name: 'recap stock',
        component: Stock,
        slug: "stockrecap",
        meta: {
          title: "Data Rekap Stok"
        }
      },
    ]
  }
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
  return { ...route, meta }
})