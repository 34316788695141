import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { TokenService } from './service/Storage.Service'

import vuetify from './plugins/vuetify'
import Convert from './plugins/Convert'
import VeeValidate from './plugins/VeeValidate'
import VueApexCharts from "./plugins/VueApexCharts"
import VCurrencyField from "./plugins/VCurrencyField"
import VueCtkDateTimePicker from './plugins/VueCtkDateTimePicker'

/**
 * Call instance Axios method get and post
 */
import { get, post } from "@/service/Axios.js";

import '@/assets/css/main.css'

/**
 * Declare component 
 * (can be used everypages )
 */
Vue.component('base-table', function (resolve) {
  require(['@/components/base/BaseTable.vue'], resolve)
})

if (TokenService.getToken()) {
  store.commit("setToken", TokenService.getToken())
  store.commit("setUser", JSON.parse(TokenService.getUser()))
}

/**
 * Adding Instance Properties
 */
Vue.prototype.$get = get  
Vue.prototype.$post = post

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  Convert,
  vuetify,
  VeeValidate,
  VueApexCharts,
  VCurrencyField,
  VueCtkDateTimePicker,
  render: h => h(App)
}).$mount('#app')
