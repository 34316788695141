const Invitation = () => import(/* webpackPrefetch: true */ '@/views/private/Invitation.vue');

const routes = [
  {
    path: "/invitation",
    name: "invitation",
    component: Invitation,
    meta: {
      title: "Inventaris"
    },
    children: [
      {
        path: '/invitations',
        name: 'invitations',
        component: Invitation,
        slug: "invitation",
        meta: {
          title: "Data Invitations"
        }
      },
    ]
  }
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
  return { ...route, meta }
})