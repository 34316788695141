const Store = () => import(/* webpackPrefetch: true */ '@/views/private/Store.vue');

const routes = [
  {
    path: "/store",
    name: "store",
    component: Store,
    meta: {
      title: "Daftar Outlet"
    },
    children: [
      {
        path: '/stores',
        name: 'stores',
        component: Store,
        slug: "store",
        meta: {
          title: "Data Store"
        }
      },
    ]
  }
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
  return { ...route, meta }
})