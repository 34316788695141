<template>
  <v-app>
    <Navbar
      v-if="
        $route.name !== 'login' &&
          $route.name !== '404'
      "
    />
    <v-main min-height="100vh" light>

      <v-snackbar
        v-model="dialog.upgrade"
        bottom
        right
        vertical
        timeout="-1"
      >
        <p class="mt-5 font-weight-bold text-center">
          Versi baru telah tersedia.
        </p>
        <p class="mt-2 text-center">
          Silahkan klik tombol Refresh untuk mendapatkan versi terbaru.
        </p>
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            color="#fff"
            v-bind="attrs"
            @click.stop="dialog.upgrade = false"
          >
            Nanti
          </v-btn>
          <v-btn
            text
            color="#00f500"
            v-bind="attrs"
            @click.stop="refreshApp"
          >
            Refresh App
          </v-btn>
        </template>
      </v-snackbar>
      <router-view />
    </v-main>
   
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar";
export default {
  components: {
    Navbar,
  },
  data: () => ({
    fab: true,
      connection: null,
      dialog: {
        upgrade: false,
      },
      refreshing: false,
      registrasi: null,
      updateExists: false,
  }),
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.path != "/login") {
        // this.preventPrevilage();
      }
    },
    
  },
  mounted() {},
  methods: {
    async updateTokenFirebase(refreshedToken) {
      if (refreshedToken !== localStorage.getItem("TOKEN_FCM")) {
        localStorage.setItem("TOKEN_FCM", refreshedToken);
      }
    },
    // preventPrevilage() {
    //   if (TokenService.getUser()) {
    //     if (!JSON.parse(TokenService.getUser()).user_privilage) {
    //       TokenService.removeToken();
    //       window.location = "/login";
    //     }
    //   }
    // },
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
      this.dialog.upgrade = true;
    },
    refreshApp() {
      this.updateExists = false;
      this.dialog.upgrade = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  }
};
</script>

<style>
#price {
  text-align: right;
}
#inputQty {
  text-align: center !important;
}
#halaman {
  text-align: center;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

#content {
  padding: 0px;
}
</style>