const Inventory = () => import(/* webpackPrefetch: true */ '@/views/private/inventory/Index.vue')
const Monitoring = () => import(/* webpackPrefetch: true */ '@/components/inventory/monitoring/Index.vue')
const Card = () => import(/* webpackPrefetch: true */ '@/components/inventory/card/Index.vue')
const Transfer = () => import(/* webpackPrefetch: true */ '@/components/inventory/transfer/Index.vue')
import { TokenService } from "@/service/Storage.Service"; 

const routes = [
  {
    path: "/inventory",
    name: "inventory",
    component: Inventory,
    meta: {
      title: "Inventaris"
    },
    children: [
      {
        path: '/inventory/monitoring',
        name: 'inventory monitoring',
        component: Monitoring,
        slug: "stock",
        meta: {
          title: "Data Monitoring Stok"
        }
      },
      {
        path: '/inventory/card',
        name: 'inventory card',
        component: Card,
        slug: "stockCard",
        meta: {
          title: "Data Kartu Stok"
        }
      },
      {
        path: '/inventory/transfer',
        name: 'inventory transfer',
        component: Transfer,
        slug: "stockTransfer",
        meta: {
          title: "Data Transfer Stok"
        }
      },
    ]
  }
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
  return { ...route, meta }
})