const Inventory = () => import(/* webpackPrefetch: true */ '@/views/private/reportRecapStock/Index.vue')
const Monitoring = () => import(/* webpackPrefetch: true */ '@/components/stockReportRecap/monitoring/Index.vue')
const Card = () => import(/* webpackPrefetch: true */ '@/components/stockReportRecap/card/Index.vue')
import { TokenService } from "@/service/Storage.Service"; 

const routes = [
  {
    path: "/report-recap-stock/:id",
    name: "inventory",
    component: Inventory,
    meta: {
      title: "Inventaris"
    },
    children: [
      {
        path: '/report-recap-stock/monitoring/:id',
        name: 'inventory monitoring',
        component: Monitoring,
        slug: "stock",
        meta: {
          title: "Data Monitoring Stok"
        }
      },
      {
        path: '/report-recap-stock/card/:id',
        name: 'inventory card',
        component: Card,
        slug: "stockCard",
        meta: {
          title: "Data Kartu Stok"
        }
      },
    ]
  }
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
  return { ...route, meta }
})